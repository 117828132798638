import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Masonry from "../../components/masonry"
import { graphql } from "gatsby"

export default ({ data }) => (
  <Layout>
    <SEO title="Workshops" />

    <section className="hero is-medium is-white">
      <div className="hero-body">
        <div className="container">
          <h1 className="title is-spaced is-size-3">Workshops</h1>
          <h3 className="is-size-5">
            John and Pam have been facilitating photography workshops all over
            North Queensland for the past 18 years, beginning with the Fujifilm
            Professional Get-a-ways, then Orpheus Island Workshops with Dr. Les
            Walkling and currently the yearly, eight day residential Daintree
            Photography Workshop with Les and other guest speakers. John also
            offers one on one tutorials at his studio in Malanda offering
            “Getting to know your DSLR” and “Advanced processing and printing”.
          </h3>
        </div>
      </div>
    </section>
    <section>
      <Masonry
        items={data.workshops.edges.map(({ node }) => ({
          image: { ...node.childImageSharp.fluid },
          publicURL: node.publicURL,
          name: node.name,
        }))}
        itemsPerRow={[1, 5]}
      />
    </section>
  </Layout>
)

export const data = graphql`
  query {
    workshops: allFile(
      filter: { relativeDirectory: { eq: "gallery/workshops" } }
    ) {
      edges {
        node {
          id
          publicURL
          name
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
